import { useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { NodeControls, ViewControls } from "../../features/view-controls";

import { useNodeInfoQuery } from "../../services/piosaasServerApi";

import { CommunityCards, cardsCompress } from "../../utils/cards";
import { Link } from "../../utils/nav";
import Spinner from "../../utils/spinner";

import HandGrid from "../hand-grid";
import SquareDetails from "../square-details";

const prepareRangeView = (range) => {
  return range.split(" ").map((s) => +s);
};

function BoardSummary({ spot, community }) {
  const isPreflop =
    community === null || community === undefined || community.trim() === "";
  return (
    <Link
      to={
        isPreflop
          ? `/board/pack/spot/`
          : `/board/pack/spot/${cardsCompress(community)}`
      }>
      <Card style={{ width: "16rem" }}>
        <Card.Body>
          <Card.Title>
            <b>{spot["hero"]}</b> vs. <b>{spot["villain"]}</b>{" "}
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            <div>
              <b>{spot["table"]}</b> table
            </div>
            <div>
              <b>{spot["depth"]}</b> deep
            </div>
            <div>
              <b>{spot["preflop"]}</b> pot
            </div>
          </Card.Subtitle>
          <Card.Text>
            {isPreflop ? "Preflop" : <CommunityCards cards={community} />}
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
  );
}

function valuesForPlayerDisplay(player, display, data) {
  const key = `${display}_${player.toUpperCase()}`;
  return data.displayValues[key][0];
}

function getRange(player, data) {
  return player === "IP" ? data.range_IP : data.range_OOP;
}

// function BigViewportDisplay({ bigViewport, children, visible }) {
//   let className = null;
//   if (bigViewport) className = "d-none d-lg-block";
//   else className = visible ? "d-block d-lg-none" : "d-none";

//   return <div className={className}>{children}</div>;
// }

function Details({ data, display, isLoading, player }) {
  return display === "Strat" ? (
    <div>Strategy not yet implemented</div>
  ) : isLoading ? (
    <Spinner />
  ) : (
    <SquareDetails
      range={prepareRangeView(getRange(player, data))}
      squareSize={90}
      values={prepareRangeView(valuesForPlayerDisplay(player, display, data))}
    />
  );
}

function BoardHandGrid({ data, display, isLoading, player }) {
  return display === "Strat" ? (
    <div>Strategy not yet implemented</div>
  ) : isLoading ? (
    <Spinner />
  ) : (
    <HandGrid
      range={prepareRangeView(getRange(player, data))}
      squareSize={30}
      values={prepareRangeView(valuesForPlayerDisplay(player, display, data))}
    />
  );
}

function Board() {
  const board = useSelector((state) => state.nodeState.board);
  const currentNodeId = useSelector((state) => state.nodeState.currentNodeId);
  const display = useSelector((state) => state.viewControls.display);
  const player = useSelector((state) => state.viewControls.player);
  const rootBoard = useSelector((state) => state.nodeState.rootBoard);

  const { data, isLoading } = useNodeInfoQuery({
    node: currentNodeId,
    board: board
  });

  return (
    <Row>
      <Col md={5} xl={4}>
        <h1 className="pb-1">
          <CommunityCards cards={board} />
        </h1>
        <div className="overflow-scroll">
          <NodeControls
            board={board}
            rootBoard={rootBoard}
            currentNodeId={currentNodeId}
          />
        </div>
      </Col>
      <Col md={5} xl={4}>
        <ViewControls />
        <BoardHandGrid
          data={data}
          display={display}
          isLoading={isLoading}
          player={player}
        />
      </Col>
      <Col md={2} xl={4}>
        <Details
          data={data}
          display={display}
          isLoading={isLoading}
          player={player}
        />
      </Col>
    </Row>
  );
}

export default Board;

export { BoardSummary };
