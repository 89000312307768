import { Fragment, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { Route, Routes, useParams } from "react-router-dom";

import About from "./components/about";
import BoardContent from "./components/board";
import DashboardContent from "./components/dashboard";
import Footer from "./components/footer";
import Help from "./components/help";
import PioSaaSNavbar from "./components/navbar";

import { HideIfNoUser, RequireUser } from "./features/auth";

import { useSpotInfoQuery } from "./services/piosaasServerApi";
import Spinner from "./utils/spinner";
import { useDispatch } from "react-redux";
import {
  initialNodeId,
  setCurrentNodeId,
  setRootBoard
} from "./features/view-controls/nodeSlice";
import ErrorBoundary from "./utils/errors/ErrorBoundary";

function Dashboard() {
  return (
    <RequireUser>
      <DashboardContent />
    </RequireUser>
  );
}

function NavBar() {
  return (
    <HideIfNoUser>
      <PioSaaSNavbar />
    </HideIfNoUser>
  );
}

function Board() {
  const { board, spot } = useParams();
  const dispatch = useDispatch();

  const { error, isLoading, isSuccess, isError, isFetching } = useSpotInfoQuery(
    {
      spot,
      board
    }
  );
  useEffect(() => {
    dispatch(setRootBoard(board));
    dispatch(setCurrentNodeId(initialNodeId));
  }, [board, dispatch]);

  return (
    <RequireUser>
      {(isLoading || isFetching) && <Spinner />}
      {isError && error.message}
      {isSuccess && <BoardContent />}
    </RequireUser>
  );
}

function App() {
  // const spinner = <Spinner />;
  return (
    <Fragment>
      <header id="navheader">
        <NavBar />
      </header>
      <main id="content" className="mx-md-5">
        <Container fluid>
          <Row>
            <Col>
              <ErrorBoundary>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/board/:pack/:spot/" element={<Board />} />
                  <Route path="/board/:pack/:spot/:board" element={<Board />} />
                  <Route path="/help" element={<Help />} />
                  <Route path="/about" element={<About />} />
                </Routes>
              </ErrorBoundary>
            </Col>
          </Row>
        </Container>
      </main>
      <footer className="footer mt-auto py-3 mx-2 mx-md-5">
        <Footer />
      </footer>
    </Fragment>
  );
}

export default App;
