import { cardsCompressAndSplit } from "../cards";

const NodeTypes = {
  OOP: "OOP",
  IP: "IP",
  SPLIT: "SPLIT",
  END_NODE: "END_NODE"
};

const PotContrib = {
  OOP: 0,
  IP: 1,
  EXTRA: 2
};

const DealActions = {
  FLOP: "<flop>",
  TURN: "<turn>",
  RIVER: "<river>"
};

const DealStrings = {
  "<flop>": "Flop",
  "<turn>": "Turn",
  "<river>": "River"
};
const DealActionCodes = {};
Object.keys(DealActions).map((k) => (DealActionCodes[DealActions[k]] = k));

const flopLength = 3;
const dealActionArray = [DealActions.FLOP, DealActions.TURN, DealActions.RIVER];

/**
 * Add the line into the collection of nodes.
 * @param {string} currentId
 * @param {string} splitLine
 * @param {string} parentId
 * @param {object} nodes
 * @returns The newly added node.
 */
function accumulateLine(currentId, splitLine, parentId, nodes) {
  let parent = nodes[parentId];

  let parentPotDiff = parent.pot[PotContrib.OOP] - parent.pot[PotContrib.IP];
  let street = parent.street;
  const currentAction = parseAction(
    splitLine[splitLine.length - 1],
    parentPotDiff
  );

  const newPot = calculateNewPot(parent, currentAction, parentPotDiff);
  const currentNode = {
    id: currentId,
    pot: newPot,
    parent: parent,
    action: currentAction,
    children: [],
    type: getNodeType(parent, currentAction, street),
    street: street
  };

  nodes[currentId] = currentNode;
  if (!parent.children.includes(currentId)) parent.children.push(currentId);
  return currentNode;
}

/**
 * Some extra accumulation happens for split nodes.
 * @param {string} currentId
 * @param {object} nodes
 * @returns The deal action id
 */
function accumulateSplitNode(currentNode, nodes) {
  const currentId = currentNode.id;
  let newStreet = getNextStreet(currentNode.street);
  let dealAction = getDealAction(newStreet);
  let dealActionId = `${currentId}:${dealAction}`;
  nodes[dealActionId] = {
    id: dealActionId,
    pot: currentNode.pot.map((a) => a),
    parent: currentNode,
    action: parseAction(dealAction),
    children: [],
    type: NodeTypes.OOP,
    street: newStreet
  };
  nodes[currentId].children.push(dealActionId);
  return dealActionId;
}

/**
 * The deal lines need to be adjusted after a split node is processed.
 * @param {array} splitLines
 * @param {string} currentId
 * @param {string} dealActionId
 * @param {object} nodes
 * @returns
 */
function fixDealLines(splitLines, currentId, dealActionId, nodes) {
  return splitLines.map((line) => {
    if (!line[0].startsWith(currentId)) return line;
    let newLine = line[0].replace(
      currentId,
      `${currentId}:${nodes[dealActionId].action.code}`
    );
    let newSplitLine = newLine.split(":");
    return [newLine, newSplitLine];
  });
}

/**
 * Take the output of show_all_lines and return an array
 * of decision points where each value contains the options
 * at each decision point.
 * @param {array} nodeLines
 * @param {string} rootPot (e.g., "0 0 10")
 * @param {string} rootBoardString (e.g., "5s 3h 2s")
 * @returns [{name, options}]
 */
function buildNodeTree(nodeLines, rootPot, rootBoardString) {
  const pot = rootPot.split(" ").map((n) => Number(n.trim()));
  const rootStreet = rootBoardString
    ? cardsCompressAndSplit(rootBoardString).length
    : 0;

  const nodes = {};

  let rootPotDiff = rootPot[PotContrib.OOP] - rootPot[PotContrib.IP];
  nodes["r:0"] = {
    id: "r:0",
    pot,
    parent: null,
    action: parseAction("0", rootPotDiff),
    children: [],
    type: rootPotDiff >= 0 ? NodeTypes.OOP : NodeTypes.IP,
    street: rootStreet
  };

  let splitLines = nodeLines
    .map((line) => [line, line.split(":")])
    .sort((a, b) => a[1].length - b[1].length);
  while (splitLines.length > 0) {
    const [currentId, splitLine] = splitLines.shift();
    if (splitLine.length < 2) continue; //just to skip r and r:0

    const parentId = getParentId(splitLine);
    if (!parentId) continue;
    const currentNode = accumulateLine(currentId, splitLine, parentId, nodes);
    if (currentNode.type === NodeTypes.SPLIT) {
      const dealActionId = accumulateSplitNode(currentNode, nodes);
      splitLines = fixDealLines(splitLines, currentId, dealActionId, nodes);
    }
  }

  for (const node of Object.keys(nodes)) {
    const nodeObj = nodes[node];
    if (nodeObj.children.length !== 0) continue;

    let endNode = nodeObj;
    if (nodeIsDealAction(node)) {
      const parent = endNode.parent;
      removeNodeFromTree(nodeObj, nodes);
      endNode = parent;
    }

    endNode.type = NodeTypes.END_NODE;
  }
  return nodes;
}

function parseAction(actionCode, potDiff) {
  if (DealActionCodes[actionCode] != null) {
    return {
      code: actionCode,
      action: actionCode,
      size: null,
      text: null
    };
  }

  let actionType = actionCode.charAt(0);
  let size = actionCode.length > 1 ? Number(actionCode.substring(1)) : null;
  let action;

  switch (actionType) {
    case "c":
      if (potDiff === 0) {
        action = "check";
        // eslint-disable-next-line brace-style
      } else {
        action = "call";
        size = Math.abs(potDiff);
      }
      break;
    case "b":
      action = size > Math.abs(potDiff) && potDiff !== 0 ? "raise" : "bet";
      break;
    case "f":
      action = "fold";
      break;
    default:
      action = "none";
      break;
  }

  let text = getNodeText(action, size);
  return {
    code: actionCode,
    action: action,
    size: size,
    text: text
  };
}

function calculateNewPot(parent, action) {
  let newPot = parent.pot.map((a) => a);
  const index = parent.type === NodeTypes.OOP ? PotContrib.OOP : PotContrib.IP;
  if (action.size) newPot[index] += action.size;
  return newPot;
}

function getNodeText(action, size) {
  if (action === "bet" || action === "raise") return action + size;
  return action;
}

function isSplitNode(parentNode, currentAction, street) {
  if (currentAction.code !== "c") return false;
  if (!parentNode.parent) return false;
  if (street === 5) return false;
  if (DealActionCodes[parentNode.action.code] != null) return false;

  return true;
}

function getParentId(splitId) {
  let splitCopy = [...splitId];
  if (splitCopy.length <= 2) return null; //r and r:0
  splitCopy.pop();
  return splitCopy.join(":");
}

function getNodeType(parent, action, street) {
  const isSplit = isSplitNode(parent, action, street);
  if (isSplit) return NodeTypes.SPLIT;
  if (parent.type === NodeTypes.SPLIT) return NodeTypes.OOP; //dealAction node - always next is OOP
  return parent.type === NodeTypes.OOP ? NodeTypes.IP : NodeTypes.OOP;
}

function getNextStreet(previousStreet) {
  let street = Number(previousStreet);
  if (street === 0) return street + flopLength;
  if (street + 1 > 5)
    throw new Error("Node tree: street cannot be higher than 5");
  return street + 1;
}

function getDealAction(previousStreet) {
  if (previousStreet === 0) return DealActions.FLOP;

  return dealActionArray[previousStreet - flopLength];
}

function trimSplitTrim(nodeLines) {
  return nodeLines
    .trim()
    .split("\n")
    .map((s) => s.trim());
}

function removeNodeFromTree(node, tree) {
  if (node.parent)
    node.parent.children.splice(node.parent.children.indexOf(node.id), 1);

  delete tree[node.id];
}
function nodeIsDealAction(node) {
  return Object.keys(DealActions).some((action) =>
    node.endsWith(DealActions[action])
  );
}

function selectedIdFromCurrentNode(node, currentNodeId) {
  for (let childId of node.children)
    if (currentNodeId.includes(childId)) return childId;

  return node.id;
}

function getParentsByStreet(currentNode) {
  const nodesByStreet = {};
  let streets = [];
  function addParentsByStreet(node) {
    let street = node.street;
    if (node.type === NodeTypes.SPLIT) street = getNextStreet(street);
    if (!nodesByStreet[street]) {
      nodesByStreet[street] = [];
      streets.unshift(street);
    }

    nodesByStreet[street].unshift(node);
    if (!node.parent) return;

    addParentsByStreet(node.parent);
  }
  addParentsByStreet(currentNode);

  return nodesByStreet;
}

function shortenBoardToStreet(node, board) {
  if (!board || !board.trim()) return "";
  let newBoard = cardsCompressAndSplit(board);

  while (newBoard.length > node.street) newBoard.pop();

  return newBoard.join("");
}

export {
  DealActions,
  DealStrings,
  NodeTypes,
  getNextStreet,
  getParentsByStreet
};
export { selectedIdFromCurrentNode, shortenBoardToStreet, trimSplitTrim };
export default buildNodeTree;
