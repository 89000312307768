import { cardRankAndSuit, cardsSplit, ranks } from "../cards";
import math from "../math";
import coll from "../collection";
import config from "./handOrder.json";
import { CardRank } from "../cardTypes";
import {
  HandSquareRow,
  HandSquare,
  HandGroupRow,
  HandGroupSquare,
  HandValue
} from "./handSpaceTypes";

type HandGroupMatrix = string[][];

// A map from groups of hands to the individual hands and their array indices
type HandGroupToHandsMapType = {
  [key: string]: { handIndexMap: { [key: string]: number } };
};

// Map from the name of the group to the location in the matrix
type HandGroupToIndexMap = {
  [key: string]: [number, number];
};

type RankToValueMap = {
  [key: string]: number;
};

const rankValue: RankToValueMap = {};
[...ranks].forEach((r, i) => {
  rankValue[r] = i;
});
/**
 * A sort function for sorting ranks.
 * @param {string} rank1 in [2..9] + [T,J,Q,K,A]
 * @param {string} rank2 in [2..9] + [T,J,Q,K,A]
 */
function cardRankOrdering(rank1: CardRank, rank2: CardRank) {
  return rankValue[rank1] - rankValue[rank2];
}

/**
 * Return the hand ranks with the higher always first.
 * @param {string} rank1 in [2..9] + [T,J,Q,K,A]
 * @param {string} rank2 in [2..9] + [T,J,Q,K,A]
 */
function canonicalHandRankOrder([rank1, rank2]: [CardRank, CardRank]) {
  if (cardRankOrdering(rank1, rank2) < 0) return [rank2, rank1];

  return [rank1, rank2];
}

function handGroupToHandsMap(
  handspaceIndexMap: HandGroupToIndexMap,
  handOrder: string
) {
  const result: HandGroupToHandsMapType = {};
  Object.keys(handspaceIndexMap).forEach((h) => {
    result[h] = { handIndexMap: {} };
  });
  handOrder.split(" ").forEach((h, i) => {
    const splitCards = cardsSplit(h);
    const cardValues = splitCards.map(cardRankAndSuit);
    const rank0 = cardValues[0].rank;
    const rank1 = cardValues[1].rank;
    if (rank0 === rank1) result[`${rank0}${rank1}`].handIndexMap[h] = i;
    else if (cardValues[0].suit === cardValues[1].suit)
      result[`${rank0}${rank1}s`].handIndexMap[h] = i;
    else result[`${rank0}${rank1}o`].handIndexMap[h] = i;
  });

  return result;
}

function handspaceMatrixIndexMap(handspaceMatrix: HandGroupMatrix) {
  const result: HandGroupToIndexMap = {};
  handspaceMatrix.forEach((r, i) => {
    r.forEach((h, j) => {
      result[h] = [i, j];
    });
  });
  return result;
}

/**
 * Class for working with handspaces.
 */
class Handspace {
  handspaceMatrix: string[][];
  handspaceIndexMap: HandGroupToIndexMap;
  handGroupToHandsMap: HandGroupToHandsMapType;
  constructor(handspaceMatrix: string[][]) {
    this.handspaceMatrix = handspaceMatrix;
    this.handspaceIndexMap = handspaceMatrixIndexMap(handspaceMatrix);
    this.handGroupToHandsMap = handGroupToHandsMap(
      this.handspaceIndexMap,
      config.handOrder
    );
  }

  /**
   * Return information about the structure of the chunked hands
   * @param {string} groupName the name of the group to return details for
   * @returns {chunkSize, numberOfChunks}
   */
  chunkedHandStructure(groupName: string) {
    const handMap = this.handGroupToHandsMap;
    const chunkSize = groupName.endsWith("s") ? 2 : 3;
    const keys = Object.keys(handMap[groupName].handIndexMap);
    const numberOfChunks = Math.floor(keys.length / chunkSize);
    return { chunkSize, numberOfChunks };
  }

  /**
   * Return all the values for a group.
   * @param {string} groupName the name of the group to return details for
   * @param {[]} values
   * @returns {[float]}
   */
  handGroupValues(groupName: string, values: number[]) {
    const handMap: HandGroupToHandsMapType = this.handGroupToHandsMap;
    const indexMap = handMap[groupName].handIndexMap;
    return Object.values(indexMap).map((idx) => values[idx]);
  }

  rangedHandspace(range: number[]) {
    return new RangedHandspace(this, range);
  }
}

function handValuesToMeanValuesMatrix(values: number[], handspace: Handspace) {
  const handMap = handspace.handGroupToHandsMap;
  return handspace.handspaceMatrix.map((r, i) => {
    return r.map((h: string, j: number) => {
      const indexMap = handMap[h].handIndexMap;
      return math.mean(
        Object.values(indexMap).map((idx: number) => values[idx])
      ) as number;
    });
  });
}

/**
 * A handspace that has a given range.
 */
class RangedHandspace {
  handspace: Handspace;
  range: number[];
  rangeMeanMatrix: number[][];
  constructor(handspace: Handspace, range: number[]) {
    this.handspace = handspace;
    this.range = range;
    this.rangeMeanMatrix = handValuesToMeanValuesMatrix(range, handspace);
  }

  /**
   * Iterate over the rows of the handspace, providing position, range and value information.
   * @param {[]} values the values to iterate over
   * @param {(row) => any} func the function to invoke
   * @returns The result of func
   */
  mapValuesRows<T>(values: number[], func: (row: HandGroupRow<T>) => T[]) {
    const hs = this.handspace;
    const hsm = hs.handspaceMatrix;
    const rangeMeans = this.rangeMeanMatrix;
    return hsm.map((r: string[], i: number) => {
      function mapValues(valFunc: (val: HandGroupSquare) => T) {
        return r.map((c: string, j: number) => {
          const rangeMean = rangeMeans[i][j];
          const handSquareValues = hs.handGroupValues(c, values);
          return valFunc({
            rowIndex: i,
            colIndex: j,
            squareName: c,
            rangeMean,
            handSquareValues
          });
        });
      }
      const row = { row: r, rowIndex: i, mapValues };
      return func(row);
    });
  }

  /**
   * Iterate over the chunks of the handGroup, providing position, range and value information.
   * @param {string} groupName the name group to return details for
   * @param {[]} values the full handspace values to iterate over
   * @param {([{colIndex, hand, rowIndex, value}]) => any} func the function to invoke
   * @returns The result of func
   *
   * The number of chunks and number of elements per chunk can depend on the group.
   */
  mapChunkedHandGroupDetails<T>(
    groupName: string,
    values: number[],
    func: (arg: HandSquareRow<T>) => T[]
  ) {
    const handMap = this.handspace.handGroupToHandsMap;
    const indexMap = handMap[groupName].handIndexMap;
    const { chunkSize } = this.handspace.chunkedHandStructure(groupName);
    const keys = Object.keys(indexMap);
    keys.reverse();
    const handDetails = keys.map((h) => {
      return { hand: h, value: values[indexMap[h]] } as HandValue;
    });
    // we want the keys and values in the reverse order
    const chunks = coll.chunk(handDetails, chunkSize) as HandValue[][];
    return chunks.map((c, i) => {
      const mapValues = (valFunc: (f: HandSquare) => T) => {
        return c.map((d, j) => {
          return valFunc({
            rowIndex: i,
            colIndex: j,
            hand: d.hand,
            value: d.value
          });
        });
      };
      const chunk = { chunk: c, rowIndex: i, mapValues };
      return func(chunk);
    });
  }
}

// You may want to call the result of handspace() shs for structured hand space
function _handspace() {
  const ranksArray = [...ranks];
  ranksArray.reverse();
  const handspaceMatrix: string[][] = [];
  ranksArray.forEach((rank1) => {
    const handspaceMatrixRow: string[] = [];
    ranksArray.forEach((rank2) => {
      const ordering = cardRankOrdering(rank1 as CardRank, rank2 as CardRank);
      let value = null;
      if (ordering > 0) value = `${rank1}${rank2}s`;
      else if (ordering < 0) value = `${rank2}${rank1}o`;
      else value = `${rank1}${rank2}`;
      handspaceMatrixRow.push(value);
    });
    handspaceMatrix.push(handspaceMatrixRow);
  });

  return new Handspace(handspaceMatrix);
}

const handspace = _handspace();

export { handspace, canonicalHandRankOrder, cardRankOrdering, RangedHandspace };
