import React from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useDispatch } from "react-redux";

import { setBoard, setCurrentNodeId } from "../nodeSlice";
import {
  cardsCompressAndSplit,
  cardsInDeck,
  PlayingCard,
  ranks as cardRanks,
  suits as cardSuits
} from "../../../utils/cards";

const streets = {
  preflop: 0,
  flop: 3,
  turn: 4,
  river: 5
};

function boardAfterDeal(board, chosenCard, cardIndex) {
  let boardArray = cardsCompressAndSplit(board);
  if (boardArray.length === cardIndex) return `${board}${chosenCard}`;
  while (boardArray.length > cardIndex + 1) boardArray.pop();
  boardArray[cardIndex] = chosenCard;
  return boardArray.reduce((prev, cur) => prev + cur);
}

function SelectionCard({ card, onCardClick, show, isChosenCard }) {
  if (!isChosenCard && !show) return null;
  const suit = card[1];
  const style = isChosenCard ? `selected suit-${suit}-borderColor` : "normal";
  return (
    <div
      onClick={() => onCardClick(card)}
      className={`selection-card-${style}`}
      disabled>
      <PlayingCard value={card} isSelected={isChosenCard} />
    </div>
  );
}

function DealInline({ board, streetString, node }) {
  const cardIndex = streets[streetString.toLowerCase()] - 1;
  const boardArray = cardsCompressAndSplit(board);

  const remainingCards = cardsInDeck(board);
  const ranks = cardRanks.split("");
  const dispatch = useDispatch();
  const cardIsChosen = boardArray && boardArray[cardIndex];
  const chosenCard = cardIsChosen ? boardArray[cardIndex] : "";
  const onCardSelection = (card) => {
    let newBoard = boardAfterDeal(board, card, cardIndex);
    dispatch(setBoard(newBoard));
    dispatch(setCurrentNodeId(node.children[0]));
  };

  const mapRankToCols = (rank, chosenCard) => {
    return cardSuits.map((suit) => (
      <Col key={`col-${suit}`}>
        <SelectionCard
          card={`${rank}${suit}`}
          show={remainingCards[suit].includes(rank)}
          isChosenCard={boardArray[cardIndex] === `${rank}${suit}`}
          onCardClick={onCardSelection}
          chosenCard={chosenCard}
        />
      </Col>
    ));
  };

  const grid = ranks.map((rank) => (
    <Row key={`row-${rank}`}>{mapRankToCols(rank, chosenCard)}</Row>
  ));

  if (cardIsChosen) {
    return (
      <Button
        className={"btn btn-dark"}
        type="button"
        size="sm"
        onClick={() => null}>
        <PlayingCard value={chosenCard} isSelected={false} />
      </Button>
    );
  }
  return (
    <Card size="sm">
      <Card.Header>
        <Card.Title>{`Select ${streetString}`}</Card.Title>
      </Card.Header>
      <Card.Body>{grid}</Card.Body>
    </Card>
  );
}

function DealDisplay({ board, streetString, onClick }) {
  const cardIndex = streets[streetString.toLowerCase()] - 1;
  const boardArray = cardsCompressAndSplit(board);

  const cardIsChosen = boardArray && boardArray[cardIndex];
  const chosenCard = cardIsChosen ? boardArray[cardIndex] : "";

  if (!cardIsChosen) return null;
  return (
    <Button
      className={"btn btn-dark"}
      type="button"
      size="sm"
      onClick={onClick}>
      <PlayingCard value={chosenCard} isSelected={false} />
    </Button>
  );
}

export default DealInline;
export { DealDisplay, streets };
