import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import useUser from "../features/auth/useUser";

function PioSaaSNavbar() {
  const { user, logout } = useUser();

  return (
    <Navbar bg="dark" variant="dark" expand="md">
      <Container fluid>
        <div className="flex-grow-0 flex-shrink-1 mb-1">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="flex-grow-0 flex-shrink-1 d-none d-md-inline">
            <LinkContainer to="/">
              <Navbar.Brand>
                <img
                  src="/logo512.png"
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                  alt="PioSOLVER logo"
                />
              </Navbar.Brand>
            </LinkContainer>
          </div>
          <div className="flex-grow-1">
            <Breadcrumb>
              <Breadcrumb.Item active>Pack</Breadcrumb.Item>
              <Breadcrumb.Item active>
                <span className="text-primary">
                  <b>BTN</b> vs. <b>BB</b> | 6 max | 100B depth | 3bet pot
                </span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="flex-shrink-1">
            <Dropdown align={{ md: "end" }}>
              <Dropdown.Toggle variant="secondary">
                {user.email}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default PioSaaSNavbar;
