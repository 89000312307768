// Partially following https://ui.dev/react-router-protected-routes-authentication/
import { useGetUserQuery, useLoginMutation, useLogoutMutation } from "../../services/piosaasServerApi";

function useUser() {
  const { data, error, isLoading } = useGetUserQuery();
  const [updateLogin, { isLoading: loginUpdating, error: loginError }] = useLoginMutation();
  const [updateLogout, { isLoading: logoutUpdating, error: logoutError }] = useLogoutMutation();

  const isUpdating = loginUpdating || logoutUpdating;

  return {
    user: data,
    userError: error,
    userLoading: isLoading,
    userUpdating: isUpdating,
    userLoginError: loginError,
    userLogoutError: logoutError,
    login: updateLogin,
    logout: updateLogout,
  };
}

export default useUser;
