import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Spinner from "../../utils/spinner";

function Login({ login, userLoginError, userUpdating }) {
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login({ email, password });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email address"
          readOnly={userUpdating}
          onChange={(e) => setEmail(e.target.value.trim())}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          readOnly={userUpdating}
          onChange={(e) => setPassword(e.target.value.trim())}
        />
      </Form.Group>
      <Form.Group
        className="d-flex flex-row-reverse align-items-center mb-3"
        controlId="submitButton">
        <div>
          <Button variant="primary" type="submit" disabled={userUpdating}>
            {userUpdating ? (
              <Spinner size={32} variant="light" message="Logging in..." />
            ) : (
              "Login"
            )}
          </Button>
        </div>
        <div className="mx-2 text-danger">
          {userLoginError ? "Incorrect username and/or password." : null}
        </div>
      </Form.Group>
    </Form>
  );
}

export default Login;
