import { configureStore } from "@reduxjs/toolkit";
import { markdownReducer } from "../features/markdown";
import { piosaasServerApi } from "../services/piosaasServerApi";
import { nodeReducer, viewControlsReducer } from "../features/view-controls";

export const store = configureStore({
  reducer: {
    markdown: markdownReducer,
    viewControls: viewControlsReducer,
    nodeState: nodeReducer,
    [piosaasServerApi.reducerPath]: piosaasServerApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(piosaasServerApi.middleware)
});
