import React, { useEffect } from "react";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { fetchMarkdownAsync, selectMarkdown } from "./markdownSlice";

function Markdown({ content, source, retrieveContent, spinner }) {
  spinner = (spinner == null) ? <div className="spinner-border" role="status">
    <span className="visually-hidden">Loading...</span>
  </div> : spinner;
  useEffect(() => {
    retrieveContent();
  }, [source, retrieveContent]);
  if (null == content) return <div className="text-center">{spinner}</div>;
  return <ReactMarkdown rehypePlugins={[rehypeRaw]} className="markdown">{content}</ReactMarkdown>;
}

const mapStateToProps = (state, ownProps) => {
  const { markdown } = state;
  const serverPath = ownProps.source;
  const content = selectMarkdown(markdown, serverPath);
  return { content };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const serverPath = ownProps.source;
  return { retrieveContent: () => dispatch(fetchMarkdownAsync(serverPath)) };
};


export default connect(mapStateToProps, mapDispatchToProps)(Markdown);
