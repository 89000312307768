import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchMarkdown } from "./markdownAPI";

const initialState = { pages: { } };

export const fetchMarkdownAsync = createAsyncThunk(
  "markdown/fetchMarkdown",
  async (serverPath, thunkApi) => {
    thunkApi.dispatch(contentRequest({ serverPath }));
    const response = await fetchMarkdown(serverPath);
    // The value we return becomes the `fulfilled` action payload
    return { serverPath, response };
  }
);

export const markdownSlice = createSlice({
  name: "markdown",
  initialState,
  reducers: {
    contentRequest: (state, action) => {
      if (state.pages[action.payload.serverPath])
        state.pages[action.payload.serverPath].status = "loading";
      else
        state.pages[action.payload.serverPath] = { status: "loading" };

    },
    contentReturn: (state, action) => {
      state.pages[action.payload.serverPath].status = "idle";
      state.pages[action.payload.serverPath].value = action.payload.response;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarkdownAsync.fulfilled, (state, action) => {
        state.pages[action.payload.serverPath].status = "idle";
        state.pages[action.payload.serverPath].value = action.payload.response;
      });
  },
});

export const { contentRequest, contentReturn } = markdownSlice.actions;

export const selectMarkdown = (state, serverPath) => (state.pages[serverPath]) ? state.pages[serverPath].value : null;

export default markdownSlice.reducer;
