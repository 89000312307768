import { Fragment } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Markdown from "../../features/markdown";
import Spinner from "../../utils/spinner";

function Help() {
  const spinner = <Spinner />;
  return <Fragment>
    <Row>
      <Col md={8} lg={6}><Markdown source="pages/help.md" spinner={spinner} /></Col>
    </Row>
  </Fragment>;
}

export default Help;
