import { Fragment } from "react";
import {
  CardsInDeck,
  CommunityCardsProps,
  PlayingCardProps,
  CardRank,
  CardSuit
} from "./cardTypes";

/**
 * Take a list of cards and compress their representation (remove spaces).
 * @param {string} cards
 * @returns The card in a compressed representation
 */
function cardsCompress(cards: string) {
  if (!cards) return "";
  return cards.replace(/\s/g, "");
}

const ranks = "23456789TJQKA";
const suits = ["s", "h", "d", "c"];

/**
 * Return the cards that could still be in the deck given a board, grouped by suit
 * @param {array} board the cards on the board
 * @returns {s: [], h: [], d: [], c: []}
 */
function cardsInDeck(board: string[]) {
  const result: CardsInDeck = { s: [], h: [], d: [], c: [] };
  for (const rank of ranks) {
    for (const suit of suits) {
      if (!board || !board.includes(rank + suit))
        result[suit as CardSuit].push(rank);
    }
  }
  return result;
}

/**
 * Extract the rank and suit from the card value
 * @param {string} value
 * @returns {rank, suit}
 */
function cardRankAndSuit(value: string) {
  const rank = value[0].toUpperCase() as CardRank;
  const suit = value[1].toLowerCase() as CardSuit;
  return { rank, suit };
}

/**
 * Break a string with multiple cards into individual cards.
 * @param {string} cards
 * @returns {array}
 */
function cardsSplit(cards: string) {
  return cards.match(/.{1,2}/g) as string[];
}

function cardsCompressAndSplit(cards: string) {
  return cardsSplit(cardsCompress(cards));
}

function PlayingCard({ value, isSelected, isPlain }: PlayingCardProps) {
  // See https://unicode-table.com/en/html-entities/#block-symbols for unicode points
  const suits = {
    s: { class: "suit-spades", htmlCode: "\u2660" },
    h: { class: "suit-hearts", htmlCode: "\u2665" },
    d: { class: "suit-diamonds", htmlCode: "\u2666" },
    c: { class: "suit-clubs", htmlCode: "\u2663" }
  };

  const { rank, suit } = cardRankAndSuit(value);
  const suitProps = suits[suit];
  if (!ranks.includes(rank) || !suit) return null;
  const className = isPlain
    ? "text-plain-white"
    : `${suitProps.class}${isSelected ? "-selected" : ""}`;

  return (
    <span className={className}>
      {rank}
      {suitProps.htmlCode}
    </span>
  );
}

function PlayingCardSvg({ value, isSelected, isPlain }: PlayingCardProps) {
  // Same as PlayingCard, but returns an svg text
  const suits = {
    s: { class: "suit-spades", htmlCode: "\u2660" },
    h: { class: "suit-hearts", htmlCode: "\u2665" },
    d: { class: "suit-diamonds", htmlCode: "\u2666" },
    c: { class: "suit-clubs", htmlCode: "\u2663" }
  };

  const { rank, suit } = cardRankAndSuit(value);
  const suitProps = suits[suit];
  if (!ranks.includes(rank) || !suit) return null;
  const className = isPlain
    ? "text-plain-white"
    : `${suitProps.class}${isSelected ? "-selected" : ""}`;

  return (
    <tspan className={className}>
      {rank}
      {suitProps.htmlCode}
    </tspan>
  );
}

function CommunityCards({ cards, isPlain }: CommunityCardsProps) {
  const splitCards = cardsCompressAndSplit(cards);
  if (!splitCards) return <span>Preflop</span>;
  const allCards = splitCards.map((card, i) => (
    <Fragment key={`community-cards-fragment${i}`}>
      {i !== 0 ? " " : null}
      <PlayingCard key={i} value={card} isPlain={isPlain} isSelected={false} />
    </Fragment>
  ));

  return <span>{allCards}</span>;
}

function HandCardsSvg({ cards, isPlain }: CommunityCardsProps) {
  const splitCards = cardsCompressAndSplit(cards);
  const allCards = splitCards?.map((card, i) => (
    <Fragment key={`hand-cards-fragment${i}`}>
      {i !== 0 ? " " : null}
      <PlayingCardSvg
        key={i}
        value={card}
        isPlain={isPlain}
        isSelected={false}
      />
    </Fragment>
  ));

  return allCards;
}

export {
  CommunityCards,
  HandCardsSvg,
  PlayingCard,
  cardsCompress,
  cardsInDeck,
  cardRankAndSuit,
  cardsSplit,
  cardsCompressAndSplit,
  ranks,
  suits
};
