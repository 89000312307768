// Partially following https://ui.dev/react-router-protected-routes-authentication/
import { Fragment } from "react";
//import { Navigate, useLocation } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Login from "./Login";
import useUser from "./useUser";
import Spinner from "../../utils/spinner";

function RequireUserLogin({ login, userLoginError, userUpdating }) {
  return (
    <Fragment>
      <h1 style={{ width: 360, fontSize: "xxx-large" }}>PioSaaS</h1>
      <Row>
        <Col md={8} lg={6}>
          <p>Welcome to PioSOLVER, a GTO poker-hand analyzer.</p>
        </Col>
      </Row>
      <Row>
        <Col md={8} lg={6}>
          <Login
            login={login}
            userLoginError={userLoginError}
            userUpdating={userUpdating}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

const canAuthenticate = true;

function HideIfNoUser({ children }) {
  const { user, userError, userLoading } = useUser();

  if (!canAuthenticate) return children;

  if (userLoading) return null;
  if (userError) return null;
  return user != null && user.email != null ? children : null;
}

function RequireUser({ children }) {
  const { user, userError, userLoading, userUpdating, userLoginError, login } =
    useUser();
  //   const location = useLocation();

  if (!canAuthenticate) return children;

  if (userLoading) return <Spinner />;

  // User is not authenticated
  if (userError && userError.status === 401) {
    return (
      <RequireUserLogin
        login={login}
        userLoginError={userLoginError}
        userUpdating={userUpdating}
      />
    );
  }

  // return (user != null && user.email != null)
  //   ? children
  //   : <Navigate to="/login" replace state={location.pathname} />;

  // Just show the login page if not logged in
  return user != null && user.email != null ? (
    children
  ) : (
    <RequireUserLogin
      login={login}
      userLoginError={userLoginError}
      userUpdating={userUpdating}
    />
  );
}

export default RequireUser;
export { HideIfNoUser, useUser };
