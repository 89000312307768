import React from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";

import { useDispatch } from "react-redux";

import { setBoard, setCurrentNodeId } from "../nodeSlice";
import { CommunityCards } from "../../../utils/cards";
import { parseFlopSubset } from "../../../utils/solverUtil";

function FlopSelection({ flop, onClick, board }) {
  const style = board.startsWith(flop)
    ? `selected suit-h-borderColor`
    : "normal";
  return (
    <div onClick={() => onClick(flop)} className={`selection-card-${style}`}>
      <CommunityCards cards={flop} />
    </div>
  );
}

function FlopInline({ board, node, flops }) {
  let flopStrings = parseFlopSubset(flops).map((flopWeight) => flopWeight.flop);

  const dispatch = useDispatch();

  const onCardSelection = (flop) => {
    dispatch(setBoard(flop));
    dispatch(setCurrentNodeId(node.children[0]));
  };

  const rows = flopStrings.map((flop) => (
    <Row key={`row-${flop}`}>
      <FlopSelection board={board} flop={flop} onClick={onCardSelection} />
    </Row>
  ));

  return (
    <>
      <Card size="sm">
        <Card.Header>
          <Card.Title>Select Flop</Card.Title>
        </Card.Header>
        <Card.Body>{rows}</Card.Body>
      </Card>
    </>
  );
}

function FlopDisplay({ board, onClick }) {
  let selectedFlop = board ? board.substring(0, 6) : "";

  if (!selectedFlop && board) return null;
  return (
    <Button
      className={"btn btn-dark"}
      type="button"
      size="sm"
      onClick={onClick}>
      <CommunityCards cards={selectedFlop} />
    </Button>
  );
}

export default FlopInline;
export { FlopDisplay };
