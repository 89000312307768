import { Fragment } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { BoardSummary } from "../board";

const spot = {
  hero: "BTN",
  villain: "BB",
  table: "6 max",
  depth: "100B",
  preflop: "3bet"
};

const community = "5s 3h 2s";

function Dashboard() {
  return (
    <Fragment>
      <Row>
        <Col md={8} lg={6}>
          <h1>PioSOLVER</h1>
          <p>
            Welcome! To get started, select a spot to view. A few are listed
            below, or you can browse for something specific.
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h1>Spots</h1>
          <p>[a few spots]</p>
          <p>Find a spot...</p>
        </Col>
        <Col>
          <h1>Recent Boards</h1>
          <BoardSummary spot={spot} community={community} />
          <BoardSummary spot={spot} community={null} />
        </Col>
      </Row>
    </Fragment>
  );
}

export default Dashboard;
