import { cardsCompressAndSplit } from "./cards";
import { DealActions } from "./nodespace";

/**
 * Take simplified node id and board
 * return solver-style node id
 * @param {string} nodeId
 * @param {string} board
 * @return {string} solverNodeId
 */
function nodeIdToSolverNodeId(nodeId, board) {
  let solverNodeId = nodeId;
  const flopPresent = nodeId.includes(DealActions.FLOP);
  const turnPresent = nodeId.includes(DealActions.TURN);
  const riverPresent = nodeId.includes(DealActions.RIVER);

  if (!flopPresent && !turnPresent && !riverPresent) return nodeId;

  const boardSplit = cardsCompressAndSplit(board);

  if (flopPresent || turnPresent || riverPresent) {
    const flop = [];
    for (let i = 0; i < 3; i++) flop.push(boardSplit.shift());
    solverNodeId = solverNodeId.replace(DealActions.FLOP, flop.join(""));
  }

  if (turnPresent || riverPresent) {
    const turn = boardSplit.shift();
    solverNodeId = solverNodeId.replace(DealActions.TURN, turn);
  }

  if (riverPresent) {
    const river = boardSplit.shift();
    solverNodeId = solverNodeId.replace(DealActions.RIVER, river);
  }

  return solverNodeId;
}

/**
 * Take subset from solver output show_subset
 * return array of objects containing flop and weight
 * @param {string} subset ()
 * @returns [{flop: <flop>: string, weight: weight: float}]
 * @returns [] (length: 0) if no flops
 */
function parseFlopSubset(subset) {
  const defaultSummary = "0 flops total";

  let flopLinesSplit = subset.trim().split("\n");
  let summary = flopLinesSplit.pop().trim(); //note changing state with pop()

  if (summary === defaultSummary) return [];

  const flopCount = Number(summary.split(" ")[0]);
  if (flopLinesSplit.length !== flopCount) {
    throw new Error(
      `Number of flops (${flopLinesSplit.length}) doesn't match number of flops in summary ${flopCount}`
    );
  }
  const parsedSubset = flopLinesSplit.map((flopLine) => {
    const [weight, ...flop] = flopLine.trim().split(" ");
    const flopString = flop.join("");

    return {
      flop: flopString,
      weight: weight
    };
  });

  return parsedSubset;
}
export { nodeIdToSolverNodeId, parseFlopSubset };
