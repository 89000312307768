import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const playerOptions = ["OoP", "IP"];
const displayOptions = ["EV", "EQ", "Strat"];
const SetSquareSource = {
  MOUSEOVER: "mouseover",
  CLICK: "click"
};

type DisplayOptions = "EV" | "EQ" | "Strat";
type PlayerOptions = "OoP" | "IP";

type SquareType = {
  handGroup: string | null;
  source: string | null;
};

type ViewControlsState = {
  player: PlayerOptions;
  display: DisplayOptions;
  square: SquareType;
};

const viewControlsSlice = createSlice({
  name: "viewControls",
  initialState: {
    player: playerOptions[0],
    display: displayOptions[0],
    square: { handGroup: null, source: null }
  } as ViewControlsState,
  reducers: {
    setPlayer(state, action: PayloadAction<PlayerOptions>) {
      state.player = action.payload;
    },
    setDisplay(state, action: PayloadAction<DisplayOptions>) {
      state.display = action.payload;
    },
    setSquare(state, action: PayloadAction<SquareType>) {
      const currentSquareFromClick =
        state.square.source === SetSquareSource.CLICK;
      const newSquareFromClick =
        action.payload.source === SetSquareSource.CLICK;
      // Click has higher precedence
      if (
        state.square.handGroup != null &&
        currentSquareFromClick &&
        !newSquareFromClick
      )
        return;
      // Treat a click as a toggle
      if (
        state.square.handGroup === action.payload.handGroup &&
        currentSquareFromClick &&
        newSquareFromClick
      ) {
        state.square.source = SetSquareSource.MOUSEOVER;
        return;
      }

      state.square = action.payload;
    }
  }
});

export const { setDisplay, setPlayer, setSquare } = viewControlsSlice.actions;
export { SetSquareSource, displayOptions, playerOptions };
export default viewControlsSlice.reducer;
