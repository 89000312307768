import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { piosaasServerApi as api } from "../../services/piosaasServerApi";
import buildNodeTree from "../../utils/nodespace";

const initialNodeId = "r:0";

type NodeState = {
  board: string;
  currentNodeId: string;
  nodeTree: NodeTree;
  rootBoard: string;
  flopSubset: string;
};

type NodeAction = {
  code: string;
  action?: string;
  size?: number;
  text: string;
};

type NodeTree = {
  id?: {
    pot?: number[];
    parent?: NodeTree;
    actions: NodeAction;
  };
};

type ServerSpotInfo = {
  spot: string;
  allLines: string[];
  boardMeta: SpotMetadata;
};

type SpotMetadata = {
  board: string;
  effectiveStack: string;
  pot: string;
  flopSubset: string;
};

const nodeSlice = createSlice({
  name: "nodeState",
  initialState: {
    board: "",
    currentNodeId: initialNodeId,
    nodeTree: {},
    rootBoard: "",
    flopSubset: ""
  } as NodeState,
  reducers: {
    setBoard(state, action: PayloadAction<string>) {
      state.board = action.payload;
    },
    setCurrentNodeId(state, action: PayloadAction<string>) {
      state.currentNodeId = action.payload;
    },
    setNodeTree(state, action: PayloadAction<NodeTree>) {
      state.nodeTree = action.payload;
    },
    setRootBoard(state, action: PayloadAction<string>) {
      state.board = action.payload;
      state.rootBoard = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.spotInfo.matchFulfilled,
      (state, { payload }: PayloadAction<ServerSpotInfo>) => {
        state.rootBoard = payload.boardMeta.board;
        state.board = payload.boardMeta.board;
        state.flopSubset = payload.boardMeta.flopSubset;
        state.nodeTree = buildNodeTree(
          payload.allLines,
          payload.boardMeta.pot,
          payload.boardMeta.board
        ) as NodeTree;
      }
    );
  }
});

export const { setBoard, setCurrentNodeId, setNodeTree, setRootBoard } =
  nodeSlice.actions;
export { initialNodeId };
export default nodeSlice.reducer;
