import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { nodeIdToSolverNodeId } from "../utils/solverUtil";

// Define a service using a base URL and expected endpoints
export const piosaasServerApi = createApi({
  reducerPath: "piosaasServerApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/" }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => "player",
      providesTags: (user) => {
        return [{ type: "User", id: user }];
      }
    }),
    login: builder.mutation({
      query: (credentials) => {
        return {
          url: "login",
          method: "POST",
          body: credentials
        };
      },
      invalidatesTags: ["User"]
    }),
    logout: builder.mutation({
      query: () => ({
        url: "logout",
        method: "POST"
      }),
      invalidatesTags: ["User"]
    }),
    spotInfo: builder.query({
      query: (arg) => {
        const { spot, board } = arg;
        const boardParam = board ? board : null;
        return { url: `spotInfo`, params: { spot, board: boardParam } };
      }
    }),
    nodeInfo: builder.query({
      query: (arg) => {
        const { node, board } = arg;
        return {
          url: `nodeInfo`,
          params: { node: nodeIdToSolverNodeId(node, board) }
        };
      }
    })
  })
});

export const {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useSpotInfoQuery,
  useNodeInfoQuery
} = piosaasServerApi;
