import { useDispatch, useSelector } from "react-redux";

import { scaleDiverging } from "d3-scale";
import { interpolateRdYlGn } from "d3-scale-chromatic";

import {
  setSquare,
  SetSquareSource
} from "../../features/view-controls/viewSlice";
import handspace from "../../utils/handspace";
import math from "../../utils/math";

const shs = handspace;

function squareFill(square, colorScale, _baseAlpha) {
  if (square.rangeMean <= 0) return "hsla(0, 0%, 30%, 0)";
  const handSquareMean = math.mean(square.handSquareValues);
  if (isNaN(handSquareMean)) return "hsla(0, 0%, 30%, 0)";
  // const alpha = square.rangeMean * baseAlpha;
  const color = colorScale(handSquareMean);
  return `${color}`;
}

function squareCursor(square) {
  const handSquareMean = math.mean(square.handSquareValues) * square.rangeMean;
  return handSquareMean > 0 ? "pointer" : "default";
}

function onMouseOver(_e, v, dispatch) {
  const handSquareMean = math.mean(v.handSquareValues) * v.rangeMean;
  let handGroup = null;
  if (handSquareMean > 0) handGroup = v.squareName;
  dispatch(setSquare({ handGroup, source: SetSquareSource.MOUSEOVER }));
}

function onMouseOut(_e, _v, dispatch) {
  dispatch(setSquare({ handGroup: null, source: SetSquareSource.MOUSEOVER }));
}

function onMouseDown(_e, v, dispatch) {
  const handSquareMean = math.mean(v.handSquareValues) * v.rangeMean;
  let handGroup = null;
  if (handSquareMean > 0) handGroup = v.squareName;
  dispatch(setSquare({ handGroup, source: SetSquareSource.CLICK }));
}

function HandGridRow({ colorScale, row, squareSize }) {
  const rowSquares = row.mapValues((v) => {
    const fill = squareFill(v, colorScale, 0.7);
    const cursor = squareCursor(v);
    const alpha = v.rangeMean * 0.7;
    return (
      <g
        className="hand-grid"
        key={v.colIndex}
        transform={`translate(${squareSize * v.colIndex})`}>
        <rect
          fill={fill}
          height={squareSize}
          width={squareSize}
          style={{ cursor }}
        />
        <text
          dx={squareSize * 0.5}
          dy={squareSize * 0.5 + 4.5}
          fill={`hsla(0, 0%, 5%, ${alpha})`}
          style={{ cursor, fontSize: "9pt" }}
          textAnchor="middle">
          {v.squareName}
        </text>
      </g>
    );
  });
  return (
    <g
      key={row.rowIndex}
      transform={`translate(0, ${squareSize * row.rowIndex})`}>
      {rowSquares}
    </g>
  );
}

function HandGridRowBorder({ dispatch, row, selectedSquare, squareSize }) {
  const rowSquares = row.mapValues((v) => {
    const cursor = squareCursor(v);
    const selected = selectedSquare.handGroup === v.squareName;
    const className = selected ? "square-border selected" : "square-border";
    return (
      <g
        className="hand-grid-border"
        key={v.colIndex}
        onMouseOut={(e) => onMouseOut(e, v, dispatch)}
        onMouseOver={(e) => onMouseOver(e, v, dispatch)}
        onMouseDown={(e) => onMouseDown(e, v, dispatch)}
        transform={`translate(${squareSize * v.colIndex})`}>
        <rect
          className={className}
          fillOpacity={0}
          height={squareSize}
          width={squareSize}
          style={{ cursor }}
        />
      </g>
    );
  });
  return (
    <g
      key={row.rowIndex}
      transform={`translate(0, ${squareSize * row.rowIndex})`}>
      {rowSquares}
    </g>
  );
}

function HandGridSvg({
  colorScale,
  height,
  rangedHandspace,
  squareSize,
  values,
  width
}) {
  const dispatch = useDispatch();
  const selectedSquare = useSelector((state) => state.viewControls.square);
  const svgRows = rangedHandspace.mapValuesRows(values, (r) => {
    return (
      <HandGridRow
        key={r.rowIndex}
        colorScale={colorScale}
        dispatch={dispatch}
        row={r}
        squareSize={squareSize}
      />
    );
  });

  const svgRowBorders = rangedHandspace.mapValuesRows(values, (r) => {
    return (
      <HandGridRowBorder
        key={`${r.rowIndex}-border`}
        dispatch={dispatch}
        row={r}
        selectedSquare={selectedSquare}
        squareSize={squareSize}
      />
    );
  });

  // The svg drawing model paints later content over earlier content, so
  // we need to do this to get nice borders
  return (
    <svg width="100%" height={height} viewBox={`0 0 ${width} ${height}`}>
      {svgRows}
      {svgRowBorders}
    </svg>
  );
}

function HandGridLegendSvg({ colorScale, height, ticksCount, width }) {
  const tickPos = colorScale.ticks(ticksCount);
  const tickFormat = colorScale.tickFormat(ticksCount);
  const squareHeight = height / tickPos.length;
  const ticksSvg = tickPos.map((t, i) => {
    const fill = colorScale(t);
    const label = tickFormat(t);
    return (
      <g key={i}>
        <rect
          x={0}
          y={squareHeight * i}
          fill={fill}
          stroke={null}
          height={squareHeight}
          width={width}
        />
        <text
          dx={width * 0.5}
          dy={squareHeight * 0.5 + 4.5}
          fill={`hsl(0, 0%, 5%))`}
          x={0}
          y={squareHeight * i}
          style={{ fontSize: "9pt" }}
          textAnchor="middle">
          {label}
        </text>
      </g>
    );
  });

  return (
    <svg width="100%" height={height} viewBox={`0 0 ${width} ${height}`}>
      {ticksSvg}
    </svg>
  );
}

function HandGridLegendRowSvg({ colorScale, height, ticksCount, width }) {
  const tickPos = colorScale.ticks(ticksCount);
  const tickFormat = colorScale.tickFormat(ticksCount);
  const squareWidth = width / tickPos.length;
  const squareHeight = height;
  const ticksSvg = tickPos.map((t, i) => {
    const fill = colorScale(t);
    const label = tickFormat(t);
    return (
      <g key={i}>
        <rect
          x={squareWidth * i}
          y={0}
          fill={fill}
          stroke={null}
          height={squareHeight}
          width={width}
        />
        <text
          dx={squareWidth * 0.5}
          dy={squareHeight * 0.5 + 4.5}
          fill={`hsl(0, 0%, 5%))`}
          x={squareWidth * i}
          y={0}
          style={{ fontSize: "9pt" }}
          textAnchor="middle">
          {label}
        </text>
      </g>
    );
  });

  return (
    <svg width="100%" height={height} viewBox={`0 0 ${width} ${height}`}>
      {ticksSvg}
    </svg>
  );
}

function HandSpaceSvg({ range, squareSize, values }) {
  const numberOfCards = shs.handspaceMatrix.length;
  const width = squareSize * numberOfCards;
  const height = squareSize * numberOfCards;
  const rhs = shs.rangedHandspace(range);
  const valueMean = math.mean(values);
  const valueBounds = math.bounds(values);
  const bounds = [valueBounds[0], valueMean, valueBounds[1]];
  const colorScale = scaleDiverging()
    .domain(bounds)
    .interpolator(interpolateRdYlGn);

  return (
    <div className="d-flex flex-column flex-md-row">
      <div className="p-2 d-inline d-md-none">
        <HandGridLegendRowSvg
          width={width}
          height={squareSize * 1.2}
          colorScale={colorScale}
          ticksCount={10}
        />
      </div>
      <div className="p-2">
        <HandGridSvg
          width={width}
          height={height}
          colorScale={colorScale}
          rangedHandspace={rhs}
          squareSize={squareSize}
          values={values}
        />
      </div>
      <div className="p-2 d-none d-md-inline">
        <HandGridLegendSvg
          width={squareSize * 1.2}
          height={height}
          colorScale={colorScale}
          ticksCount={10}
        />
      </div>
    </div>
  );
}

function HandGrid({ range, squareSize, values }) {
  return (
    <div className="d-flex">
      <HandSpaceSvg range={range} squareSize={squareSize} values={values} />
    </div>
  );
}

export default HandGrid;
