import { Fragment } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function About() {
  return (
    <Fragment>
      <Row>
        <Col md={8} lg={6}>
          <h1>About Us</h1>
          <p>
            We are small team of programmers interested in algorithms, code
            optimization and poker. We have a lot of experience in writing fast
            code, solving games and creating various analytic tools.
          </p>
          <p>
            Currently solving poker takes most of our time but if you have any
            business enquiries you can contact us at{" "}
            <a href="mailto:piosolver@piosolver.com">support@piosolver.com</a>
          </p>
          <p>
            If you have any questions/suggestions as to the PioSOLVER itself
            please write to{" "}
            <a href="mailto:support@piosolver.com">support@piosolver.com</a>
          </p>
        </Col>
      </Row>
    </Fragment>
  );
}

export default About;
