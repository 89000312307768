import { useDispatch, useSelector } from "react-redux";

import Card from "react-bootstrap/Card";
import CloseButton from "react-bootstrap/CloseButton";

import { scaleDiverging } from "d3-scale";
import { interpolateRdYlGn } from "d3-scale-chromatic";

import {
  setSquare,
  SetSquareSource
} from "../features/view-controls/viewSlice";

import { HandCardsSvg } from "../utils/cards";
import handspace from "../utils/handspace";
import math from "../utils/math";

const shs = handspace;

function formattedFloat(num) {
  return num.toFixed(2);
}

function squareFill(value, colorScale, _baseAlpha) {
  if (isNaN(value)) return "hsla(0, 0%, 30%, 0)";
  const color = colorScale(value);
  return `${color}`;
}

function SquareDetailsGridSvg({
  colorScale,
  handGroup,
  height,
  rangedHandspace,
  squareSize,
  values,
  width
}) {
  const svgRows = rangedHandspace.mapChunkedHandGroupDetails(
    handGroup,
    values,
    (r) => {
      const rowSquares = r.mapValues((v) => {
        const fill = squareFill(v.value, colorScale, 0.7);
        const cursor = "default";
        const alpha = v.rangeMean * 0.7;
        return (
          <g
            className="hand-details"
            key={v.colIndex}
            transform={`translate(${squareSize * v.colIndex})`}>
            <rect
              fill={fill}
              height={squareSize}
              width={squareSize}
              style={{ cursor }}
            />
            <rect
              fill="rgba(255, 255, 255, 0.6)"
              x={squareSize * 0.05}
              y={squareSize * 0.2}
              height={squareSize * 0.6}
              width={squareSize * 0.9}
              style={{ cursor }}
            />
            <text
              dx={squareSize * 0.5}
              dy={squareSize * 0.5 + 4.5}
              fill={`hsla(0, 0%, 5%, ${alpha})`}
              style={{ cursor, fontSize: "16pt" }}
              textAnchor="middle">
              <HandCardsSvg cards={v.hand} />
            </text>
            <text
              dx={squareSize * 0.5}
              dy={squareSize * 0.5 + 22.5}
              fill={`hsla(0, 0%, 5%, ${alpha})`}
              style={{ cursor, fontSize: "12pt" }}
              textAnchor="middle">
              {formattedFloat(v.value)}
            </text>
          </g>
        );
      });
      return (
        <g
          key={r.rowIndex}
          transform={`translate(0, ${squareSize * r.rowIndex})`}>
          {rowSquares}
        </g>
      );
    }
  );

  return (
    <svg width="100%" height={height} viewBox={`0 0 ${width} ${height}`}>
      {svgRows}
    </svg>
  );
}

function CloseHandGroup({ source }) {
  const dispatch = useDispatch();
  if (source !== SetSquareSource.CLICK) return null;
  return (
    <CloseButton
      onClick={() => {
        dispatch(setSquare({ handGroup: null, source: SetSquareSource.CLICK }));
      }}
    />
  );
}

function SquareDetailsSvg({ range, squareSize, values }) {
  const square = useSelector((state) => state.viewControls.square);
  const handGroup = square.handGroup;
  if (handGroup == null) return null;

  const chunkedHandStructure = shs.chunkedHandStructure(handGroup);
  const width = squareSize * chunkedHandStructure.chunkSize;
  const height = squareSize * chunkedHandStructure.numberOfChunks;

  const rhs = shs.rangedHandspace(range);
  const valueMean = math.mean(values);
  const valueBounds = math.bounds(values);
  const bounds = [valueBounds[0], valueMean, valueBounds[1]];
  const colorScale = scaleDiverging()
    .domain(bounds)
    .interpolator(interpolateRdYlGn);

  const handGroupMean = math.mean(shs.handGroupValues(handGroup, values));
  const headerFill = squareFill(handGroupMean, colorScale, 0.7);

  return (
    <Card>
      <Card.Header>
        <div className="d-flex justify-content-between">
          <div
            className="text-dark px-2"
            style={{ backgroundColor: headerFill }}>
            <span className="fs-3">{handGroup}</span>
            <span> {formattedFloat(handGroupMean)}</span>
          </div>
          <div>
            <CloseHandGroup source={square.source} />
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-column flex-md-row">
          <div className="p-2">
            <SquareDetailsGridSvg
              width={width}
              height={height}
              colorScale={colorScale}
              handGroup={handGroup}
              rangedHandspace={rhs}
              squareSize={squareSize}
              values={values}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

function SquareDetails({ range, squareSize, values }) {
  return (
    <div className="d-flex flex-wrap" style={{ minHeight: "100px" }}>
      <SquareDetailsSvg range={range} squareSize={squareSize} values={values} />
    </div>
  );
}

export default SquareDetails;
