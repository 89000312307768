import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useDispatch, useSelector } from "react-redux";
import {
  DealStrings,
  NodeTypes,
  getParentsByStreet,
  selectedIdFromCurrentNode,
  shortenBoardToStreet
} from "../../utils/nodespace";

import DealInline, { DealDisplay } from "./deals/DealInline";
import FlopInline, { FlopDisplay } from "./deals/FlopInline";
import { setCurrentNodeId, setBoard } from "./nodeSlice";

// import { Link } from "../../utils/nav";
const availableStreets = [0, 3, 4, 5];

function variantForAction(action) {
  if (action === "call") return "success";
  if (action === "check") return "success";
  if (action === "bet") return "danger";
  if (action === "raise") return "warning";
  return "primary";
}

function onSelectValue(dispatch, allNodes, board, value) {
  dispatch(setCurrentNodeId(value));
  dispatch(setBoard(shortenBoardToStreet(allNodes[value], board)));
}

function NodeSelectorBack({ allNodes, node, board }) {
  const dispatch = useDispatch();
  if (!node.parent) return null;
  const onClick = () =>
    onSelectValue(dispatch, allNodes, board, node.parent.id);

  return (
    <Button
      className="mx-1"
      size="sm"
      style={{ width: "fit-content" }}
      onClick={onClick}
      variant="secondary">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-arrow-counterclockwise"
        viewBox="0 0 16 16">
        <path
          fill-rule="evenodd"
          d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
        />
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
      </svg>
    </Button>
  );
}

function NodeSelectorQuickControl({ allNodes, board, node }) {
  const dispatch = useDispatch();

  if (node.type === NodeTypes.END_NODE) return "End";

  return (
    <span className="my-1">
      {node.children.map((childId, i) => (
        <Button
          className="mx-1"
          id={childId}
          key={i}
          onClick={(e) => onSelectValue(dispatch, allNodes, board, childId)}
          size="sm"
          value={childId}
          variant={variantForAction(allNodes[childId].action.action)}>
          {allNodes[childId].action.text}
        </Button>
      ))}
    </span>
  );
}

function DealControl({ node, board, allNodes, flopSubset }) {
  if (node.street === 0) {
    return (
      <div className="mb-1" style={{ maxWidth: "300px" }}>
        <FlopInline board={board} node={node} flops={flopSubset} />
      </div>
    );
  }

  const firstChild = allNodes[node.children[0]];
  return (
    <div className="mb-1" style={{ maxWidth: "300px" }}>
      <DealInline
        streetString={DealStrings[firstChild.action.code]}
        node={node}
        board={board}
        key={`${DealStrings[firstChild.action.code]}Inline`}
      />
    </div>
  );
}

function NodeSelectorQuick({ board }) {
  const flopSubset = useSelector((state) => state.nodeState.flopSubset);
  const currentNodeId = useSelector((state) => state.nodeState.currentNodeId);
  const allNodes = useSelector((state) => state.nodeState.nodeTree);

  const node = allNodes[currentNodeId];
  return (
    <div className="my-1">
      <span>Action </span>{" "}
      {node.type === NodeTypes.SPLIT ? (
        <>
          <DealControl
            allNodes={allNodes}
            board={board}
            flopSubset={flopSubset}
            node={node}
          />
        </>
      ) : (
        <NodeSelectorQuickControl
          allNodes={allNodes}
          board={board}
          node={node}
        />
      )}
      <NodeSelectorBack allNodes={allNodes} board={board} node={node} />
    </div>
  );
}

function NodeSelectorButton({ onClick, selected }) {
  if (selected == null) return null;
  const action = selected.action;
  const variant = variantForAction(action.action);
  return (
    <Button
      size="sm"
      style={{ width: "fit-content" }}
      onClick={onClick}
      variant={variant}>
      {action.action[0]}
    </Button>
  );
}

function NodeSelector({ allNodes, node, board, currentNodeId }) {
  const dispatch = useDispatch();

  if (node.type === NodeTypes.END_NODE) return "End";

  const selectedId = selectedIdFromCurrentNode(node, currentNodeId);
  if (node.id === selectedId) return null;

  return (
    <NodeSelectorButton
      onClick={() => onSelectValue(dispatch, allNodes, board, node.id)}
      selected={allNodes[selectedId]}
    />
  );
}

function DealButton({ node, board, allNodes, flopSubset }) {
  const dispatch = useDispatch();

  if (node.street === 0) {
    return (
      <FlopDisplay
        board={board}
        onClick={() => onSelectValue(dispatch, allNodes, board, node.id)}
      />
    );
  }

  const firstChild = allNodes[node.children[0]];
  return (
    <DealDisplay
      streetString={DealStrings[firstChild.action.code]}
      board={board}
      onClick={() => onSelectValue(dispatch, allNodes, board, node.id)}
      key={`${DealStrings[firstChild.action.code]}Display`}
    />
  );
}

function NodeDnaStreet({
  rowNodes,
  allNodes,
  board,
  currentNodeId,
  isRootStreet
}) {
  const flopSubset = useSelector((state) => state.nodeState.flopSubset);
  if (!rowNodes) return null;
  if (rowNodes.length < 2 && isRootStreet) return <span>&nbsp;</span>;

  return (
    <>
      {rowNodes.map((node, i) => (
        <div key={i} className="mx-1">
          {node.type === NodeTypes.SPLIT ? (
            <DealButton
              allNodes={allNodes}
              board={board}
              flopSubset={flopSubset}
              node={node}
            />
          ) : (
            <NodeSelector
              allNodes={allNodes}
              board={board}
              currentNodeId={currentNodeId}
              node={node}
              key={node.id}
            />
          )}
        </div>
      ))}
    </>
  );
}

function NodeDnaView({ board, rootBoard }) {
  const currentNodeId = useSelector((state) => state.nodeState.currentNodeId);
  const allNodes = useSelector((state) => state.nodeState.nodeTree);
  const nodesByStreet = getParentsByStreet(allNodes[currentNodeId]);

  return (
    <div className="d-flex">
      {availableStreets.map((street) => (
        <NodeDnaStreet
          key={`streetRow${street}`}
          rowNodes={nodesByStreet[street]}
          allNodes={allNodes}
          currentNodeId={currentNodeId}
          board={board}
          rootBoard={rootBoard}
          street={street}
          isRootStreet={allNodes["r:0"].street === street}
        />
      ))}
    </div>
  );
}

function NodeDnaControls({ board, rootBoard }) {
  return (
    <>
      <Row>
        <Col>
          <NodeDnaView board={board} rootBoard={rootBoard} />
          <NodeSelectorQuick board={board} rootBoard={rootBoard} />
        </Col>
      </Row>
    </>
  );
}

export default NodeDnaControls;
