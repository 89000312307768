/**
 * Math utilities
 */

import { max, mean, min } from "d3-array";

const MathFunctions = {
  mean(array) {
    return mean(array);
  },
  bounds(array) {
    return [min(array), max(array)];
  }
};

export default MathFunctions;
