import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ToggleButton from "react-bootstrap/ToggleButton";

import {
  playerOptions,
  displayOptions,
  setPlayer,
  setDisplay
} from "./viewSlice";

function PlayerControls() {
  const player = useSelector((state) => state.viewControls.player);
  const dispatch = useDispatch();
  return (
    <Form>
      <Form.Group className="mb-3" controlId="player">
        <Form.Label>Player</Form.Label>
        <Form.Check>
          <ButtonGroup>
            {playerOptions.map((p, i) => (
              <ToggleButton
                checked={player === p}
                id={`player-${i}`}
                key={i}
                name="radio"
                onChange={(e) => dispatch(setPlayer(e.currentTarget.value))}
                size="sm"
                type="radio"
                value={p}
                variant={player === p ? "primary" : "secondary"}>
                {p}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Form.Check>
      </Form.Group>
    </Form>
  );
}

function DisplayControls() {
  const display = useSelector((state) => state.viewControls.display);
  const dispatch = useDispatch();
  return (
    <Form>
      <Form.Group className="mb-3" controlId="display">
        <Form.Label>Display</Form.Label>
        <Form.Check>
          <ButtonGroup>
            {displayOptions.map((p, i) => (
              <ToggleButton
                checked={display === p}
                id={`display-${i}`}
                key={i}
                name="radio"
                onChange={(e) => dispatch(setDisplay(e.currentTarget.value))}
                size="sm"
                type="radio"
                value={p}
                variant={display === p ? "primary" : "secondary"}>
                {p}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Form.Check>
      </Form.Group>
    </Form>
  );
}

function ViewControls() {
  return (
    <Fragment>
      <Row>
        <Col className="d-flex" md={8} lg={6}>
          <div className="me-2">
            <PlayerControls />
          </div>
          <div>
            <DisplayControls />
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

export default ViewControls;
