import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

function Oops({ onClearError }) {
  return (
    <>
      <h1>Oops!</h1>
      <p className="fs-3">Something went wrong. Sorry about that!</p>
      <div>
        If you want,{" "}
        <Button onClick={onClearError} size="sm">
          Retry
        </Button>{" "}
        your action and maybe the problem will go away.
      </div>
      <div className="pt-4">
        Otherwise, you should{" "}
        <Link to="/">
          <Button onClick={onClearError}>Return to homepage</Button>
        </Link>
      </div>
    </>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  //potential logging:
  //   componentDidCatch(error, errorInfo) {
  //   }

  render() {
    if (this.state.hasError)
      return <Oops onClearError={() => this.setState({ hasError: false })} />;

    return this.props.children;
  }
}

export default ErrorBoundary;
